import React from "react"
import DatenschutztextMDX from "../sections/datenschutztext"
import { Grid, Box } from "theme-ui";

const Datenschutz = () => (
    <Grid
        pt={100}
        pb={100}
        pl={4}
        pr={4}
        columns={[ 1, 1, 1, 1, 2 ]}
        id="datenschutz"
    >
        <Box>
            <DatenschutztextMDX/>
        </Box>
    </Grid>
)

export default Datenschutz;