import React from "react"
import Layout from "../components/layout"
import Bannerpage from "../components/bannerpage"
import Datenschutz from "../components/datenschutz"
import Footer from "../components/footer";
import { Divider } from "theme-ui";
import SEO from "../components/seo";

const MinimalDatenschutz = () => (
    <>
         <Bannerpage/>
        <Layout>
            <SEO title="Datenschutz" />
            <Datenschutz/>
            <Divider />
            <Footer/>
        </Layout>
    </>
)

export default MinimalDatenschutz;